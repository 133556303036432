// core color
$adn-color-midnight: #153249;
$adn-color-dusk: #476276;
$adn-color-accent: #0086a8;
$adn-color-horizon: #92b0bf;
$adn-color-lagoon: #93c9c3;
$adn-color-sky: #8dd3fb;
$adn-color-brand: #ff3c00;
$adn-color-earth: #d6d9dc;
$adn-color-stone: #787878;
$adn-color-white: #ffffff;

// interface color
$adn-color-alert: #ff2700;
$adn-color-alert-brighter: #ffd8cc;
$adn-color-warning: #ffbc00;
$adn-color-green: #009b61;
$adn-color-background: #f8f8f8;
$adn-color-background-gray91: #E8E8E8;
$adn-color-primary: #153249;
$adn-color-primary-brighter: #b9c2c8;
$adn-color-primary-darker: #0c1f31;
$adn-color-accent-brighter: #b3dbe5;
$adn-color-accent-darker: #00698d;
$adn-color-bright-border: #d6d9dc;
$adn-color-label-dimmed: #787878;
$adn-color-disabled: #b9c2c8;
$adn-color-divider: #476276;
$adn-color-coral: #ff7069;
$adn-color-sea: #008FFF;

// table color
$adn-color-table-disabled: #e5e5e5;
$adn-color-table-striped: #e0e8ef;

$adn-color-icon-alert: #FFA200;

$fa-font-path: "/assets/webfonts";