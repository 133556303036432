@use 'variables';

$theme-colors: (
  "primary": $adn-color-primary,
  "danger": $adn-color-alert,
);
$font-family-sans-serif: "IBM Plex Sans", sans-serif;
$headings-font-weight: 700;
$headings-font-family: "Poppins", sans-serif;
$body-color: $adn-color-primary;
$body-bg: $adn-color-background;
@import "node_modules/bootstrap/scss/bootstrap.scss";
